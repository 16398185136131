
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$geek-grey: (
  50: #EAE9E9,
  100: #CAC8C8,
  200: #A6A3A3,
  300: #827E7E,
  400: #686262,
  500: #4D4646,
  600: #463F3F,
  700: #342F2F,
  800: #342F2F,
  900: #252020,
  A100: #F87878,
  A200: #F54747,
  A400: #FF0A0A,
  A700: #F00000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$geek-green: (
  50: #F0F9F2,
  100: #D9F0DE,
  200: #BFE6C8,
  300: #A5DCB2,
  400: #92D5A2,
  500: #7FCD91,
  600: #77C889,
  700: #6CC17E,
  800: #62BA74,
  900: #4FAE62,
  A100: #FFFFFF,
  A200: #DAFFE1,
  A400: #A7FFB8,
  A700: #8EFFA3,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);



$GeekWerksLLC-primary: mat-palette($geek-grey);
$GeekWerksLLC-accent: mat-palette($geek-green, 500);

// The warn palette is optional (defaults to red).
$GeekWerksLLC-warn: mat-palette($mat-red);

$primary: map-get($geek-grey, 700);
$accent: map-get($geek-green, 500);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$GeekWerksLLC-theme: mat-dark-theme((
  color: (
    primary: $GeekWerksLLC-primary,
    accent: $GeekWerksLLC-accent,
    warn: $GeekWerksLLC-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($GeekWerksLLC-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; padding: 0; margin: 0; }
body { font-family: Roboto, "Helvetica Neue", sans-serif; }

body {
  background-color: map-get($geek-green, 800);
}

.mat-toolbar {
  background: $primary;
  max-height: 40px;
}

.mat-toolbar .menu {
  color: $accent;
}

.mat-toolbar .logo {
  max-width: 256px;
  height: 32px;
}

.example-spacer {
  flex: 0 1 5px;
}

.mat-sidenav-container {
  height: calc(100% - 40px);
  width: 100%;
  position: absolute;
  background-color: map-get($geek-green, 800);
  top: 0;
  left: 0;
  right: 0;
}

.mat-sidenav {
  width: fit-content;
  overflow-x: hidden;
}

.mat-sidenav .mat-flat-button {
  font-weight: bold;
}

.mat-sidenav .mat-flat-button:hover {
  color: $accent;
}

.mat-sidenav a {
  text-decoration: none;
  font-weight: bold;
  color: white;
  font-size: 15px;
}

.mat-sidenav a div {
  padding: 15px 10px 15px 10px;
  min-width: 200px;
}

.mat-sidenav a div:hover {
  background: map-get($geek-green, 500);
}

footer div {
  width: 80%;
  font-size: 10px;
  margin-top: 50px;
  margin-bottom: -50px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.panel {
  mat-dialog-container {
    background: $primary !important;
    width: 100%;
    height: 100%;
  }
}

.postcomment {
  mat-dialog-container {
    background: $primary !important;
  }
}

.mat-form-field-appearance-outline {
  // .mat-form-field-outline {
  //   color: $outline-color;
  // }
    color: $accent;

  .mat-form-field-label {
    color: white;
  }

  .mat-form-field-outline-thick {
    color: $accent;
  }

  &.mat-focused {
    .mat-form-field-label {
      color: white;
    }

    .mat-form-field-outline-thick {
      color: $accent;
    }

    &.mat-accent .mat-form-field-outline-thick {
      color: $accent;
    }

    &.mat-warn .mat-form-field-outline-thick {
      color: red;
    }
  }

  // Class repeated so that rule is specific enough to override focused accent color case.
  &.mat-form-field-invalid.mat-form-field-invalid {
    .mat-form-field-outline-thick {
      color: red;
    }
  }

}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $accent;
}

.http-Success {
  color: white;
  background: $accent;
  font-weight: bold;
  width: auto;
  border-radius: 5px;
  padding: 5px;
}

.http-Fail {
  color: white;
  background: red;
  font-weight: bold;
  width: auto;
  border-radius: 5px;
  padding: 5px;
}

.welcome {
  font-size: 12px;
  color: $accent;
  padding-left: 10px;
  vertical-align: middle;
}